import React from 'react'
// import PropTypes from 'prop-types'
import Styled from 'styled-components'

import Md from '../../components/Markdown'

import {graphql, Link} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from '../../components/Seo'
import Main, {colors} from '../../styles/Styles'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Footer from '../../components/Footer'

function ProjectPage({data: {strapiProject}}){
	
	const project = strapiProject

	return (
		<ProjectPageStyles>
			<Header/>
			
			<Seo
				title={project.Title}
				description={"Il progetto "+project.Title}
				image={project.Cover.formats.thumbnail.url}
				type="article"
				extra={{
					'article:published_time':project.publishedAt,
					'article:modified_time':project.updatedAt,
					'article:author':project.author,
				}}
			/>
			
			<article>
				
				<h2 className="title">{project.Title.replace(': ', ':\n')}</h2>
				
				<p className="title">{project.Subtitle}</p>
				{!!project.Cover &&
				<GatsbyImage alt={project.Cover.alternativeText} image={getImage(project.Cover.childStrapiCloudinaryBridge)}/>
				}
				<Md>{project.Description.data.childMarkdownRemark.html}</Md>
				
				{!!project.links.length &&
				<div className="linksContainer">
					{project.links.map(({title, subtitle, url, image}, id) =>

						<Button url={url} key={id}>

							<div className="continue">
								<GatsbyImage image={getImage(image.childStrapiCloudinaryBridge)} alt={title || image.alternativeText}/>
								<div>
									<p>{subtitle}</p>
									<h6>{title}</h6>
								</div>
							</div>
						</Button>
					)}
				</div>
				}
				
			</article>
			
			<div className="writeUs">
				<p>
				Hai anche tu un’idea vulcanica?<br/>
				Scopri come possiamo aiutarti a...
				</p>
				
				<Link to='/#contactUs'>
					lavoriamo insieme
				</Link>
			</div>
			
			<Footer/>
		</ProjectPageStyles>
	)
}//

const ProjectPageStyles = Styled(Main)`

display:flex;
flex-direction:column;
align-items:center;

padding:0;

article {
	box-sizing:border-box;
	width:100%;
	
	padding:0 24.65vw;	//350
	
	@media (orientation:portrait){
		padding:0 3.85vw;
	}
	
	
	
	h2.title {
		white-space:break-spaces;
		margin:15.69vw 0 .83vw;	//226 12
		
		@media (orientation:portrait){
			margin:43.59vw 0 3.85vw;	//170 15
		}
	}
	
	p.title {
		color: ${() => colors.red};
		font-size: 1.25vw;	//18
		line-height: 1.46vw;	//21
		margin:0;
		
		@media (orientation:portrait){
			font-size: 4.62vw;	//18
			line-height: 5.38vw;	//21
		}
	}
	
	img, video {
		width:100%;
	}
	
	img.title {
//		height:27.78vw;	//400
		object-fit:cover;
		margin:3.47vw 0;	//50
		
		@media (orientation:portrait){
// 			height:56.41vw;
			margin:12.82vw 0;	//50
		}
	}
	
	
	.linksContainer{
		margin-top:7.64vw;		//150
	}

	.styledButton {
		width:100%;
		height:10.42vw;	//150
		margin-bottom:3.47vw;	//50
		
		@media (orientation:portrait){
			height:calc(100vw - 3.38vw *2);
			
			margin:28.21vw 0 51.28vw;	//110 200
		}
	}
	
	.continue {
		display:flex;
		
		height:100%;
		
		img {
			width:initial;
			height:100%;
			flex-basis:35%;
			
			object-fit:cover;
		}
		
		p {
			font-family:manrope;
			font-size: 1.25vw;	//18
			line-height: 1.74vw;	//25
			
			margin:0;
		}
		
		h6 {
			margin:0;
			
		}
		
		div {
			
			flex-grow:2;
			
			padding:0 4.17vw;	//50
			display:flex;
			flex-direction:column;
			justify-content:center;
		}
		
		@media (orientation:portrait){
			flex-direction:column-reverse;
			
			img {
				flex-basis:65%;
			}
			
			p {
				display:none;
			}
			
			div {
				padding:0 15%;
			}
		}
	}
}

.writeUs {
	background: ${() => colors.red};
	box-sizing:border-box;
	width:100%;
	padding:9.03vw 11.5vw;	//130

	margin-top:15.69vw;

	@media (orientation:portrait){
		text-align:center;
		
		padding:38.46vw 3.85vw;	//150
	}
	
	p{
		color:${() => colors.white};
	}
	
	a {
		text-decoration:none!important;
		
		cursor:pointer;
		
		display:flex;
		align-items:center;
		justify-content:center;
		
		padding: .69vw 2.78vw;	//10 40
		
		background: ${() => colors.white};
		border-radius: 10vw;
		
		box-sizing:border-box;
		
		width:16.25vw;	//234
		
		transition:.2s;
		
		border: .14vw solid ${() => colors.red};
		
		&:hover, &:focus{
			
			background:${() => colors.red};
			color:${() => colors.white};
			border-color:${() => colors.white};
		}
		
		@media(orientation:portrait){
			width:65%;
			margin:10.26vw auto 0;
			padding: 2.56vw 10.26vw;	//10 40
			border: .51vw solid ${() => colors.red};
		}
	}
}

`

export const query = graphql`
query($Url: String) {
	strapiProject (Url: {eq: $Url}) {
		author
		Title
		Subtitle
		Personal
		Description {
			data {
				childMarkdownRemark {
					html
				}
			}
		}
		publishedAt
		updatedAt
		Cover {
			alternativeText
			childStrapiCloudinaryBridge {
				gatsbyImageData
			}
			formats {
				thumbnail {
					url
				}
			}
		},
		links {
			title
			subtitle
			url
			image {
				alternativeText
				childStrapiCloudinaryBridge {
					gatsbyImageData
				}
			}
		}
	}
}
`

export default ProjectPage
